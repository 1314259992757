<template>
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card p-fluid">
                <h5>{{ metodologia.id ? 'Atualizar Metodologia' : 'Novo Metodologia' }}</h5>
                <div class="field col-9">
                    <label class="required">Título</label>
                    <InputText type="text" v-model="metodologia.title" />
                </div>
                <div class="field col-9">
                    <label class="required">Metodologia Sas</label>
                    <Dropdown v-model="metodologia.codigoSas" :options="metodologiaSas" optionValue="ID" optionLabel="Nome" showClear />
                </div>
                <div class="field-checkbox col-10">
                    <Checkbox id="binary" v-model="metodologia.active" :binary="true" />
                    <label for="binary">Ativo</label>
                </div>
            </div>
            <Button label="Cancelar" @click.stop="cancelar" icon="pi pi-times-circle" class="mr-2 p-button-secondary"></Button>
            <Button label="Salvar" @click.stop="inserir" icon="pi pi-check-circle" :disabled="!metodologia.title || !metodologia.codigoSas"></Button>
        </div>
    </div>
</template>
<script>
import Services from './service';

export default {
    data() {
        return {
            metodologia: {
                active: true,
                title: null,
                codigoSas: null,
                descricaoSas: null,
            },
            metodologiaSas: [],
        };
    },
    mounted() {
        if (this.$route.params.id) {
            Services.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.metodologia = response.data;
                }
            });
        }
        this.obterModalidadesSas();
    },

    methods: {
        inserir() {
            this.$store.dispatch('addRequest');

            if (this.metodologia.id) {
                Services.atualizar(this.metodologia.id, this.metodologia).then((response) => {
                    this.respostaSalvar(response, true);
                });
            } else {
                Services.inserir(this.metodologia).then((response) => {
                    this.respostaSalvar(response, false);
                });
            }
        },
        respostaSalvar(response, edicao) {
            if (response?.success) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Metodologia',
                    detail: `Metodologia ${edicao ? 'editado' : 'inserido'} com sucesso`,
                    life: 3000,
                });

                this.cancelar();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: response.errors[0],
                    life: 10000,
                });
            }
            this.$store.dispatch('removeRequest');
        },
        obterModalidadesSas() {
            this.$store.dispatch('addRequest');
            Services.obterModalidadesSas().then((response) => {
                if (response?.success) {
                    this.metodologiaSas = response.data;
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: response.errors[0],
                        life: 10000,
                    });
                    let metodologiaDto = {
                        ID: this.metodologia.codigoSas,
                        Nome: this.metodologia.descricaoSas,
                    };
                    this.metodologiaSas.push(metodologiaDto);
                }
                this.$store.dispatch('removeRequest');
            });
        },
        cancelar() {
            this.$router.push({
                name: `Metodologia`,
            });
        },
    },
    watch: {
        'metodologia.codigoSas'() {
            if (this.metodologia.codigoSas > 0 && this.metodologiaSas.length > 0) {
                const result = this.metodologiaSas.filter((metodologia) => metodologia.ID == this.metodologia.codigoSas);
                this.metodologia.descricaoSas = result[0].Nome;
                this.metodologia.codigoSas = result[0].ID;
            }
        },
    },
};
</script>
